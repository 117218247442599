/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
.leaderboardPage .component-container {
  overflow: hidden;
}
.leaderboardPage .component-container .asm-leaderboard-container .nav-buttons .button.inactive:hover span {
  color: #292929;
}
.leaderboardPage .component-container .asm-leaderboard-container .nav-buttons .button.active {
  background-color: #807D7D;
  color: #fff;
}
@media screen and (max-width: 29.99em) {
  .leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters {
    font-size: 0.7em;
  }
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters .button {
  border: 1px solid #8E7A96;
  margin-left: 10px;
  margin-right: 10px;
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters .button.inactive {
  color: #807D7D;
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters .button.inactive.toggle:hover span {
  color: #807D7D;
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters .button span {
  padding: 0;
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters .button.active {
  background-color: #8E7A96;
  color: #fff;
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-filters .button.active span {
  border-bottom: 0px;
}
.leaderboardPage .component-container .asm-leaderboard-container .leaderboard .leaderboard-player .self {
  border-radius: 0px;
}
.leaderboardPage .component-container .asm-leaderboard-container .leaderboard .highlightborder {
  border-color: #807D7D;
}
.leaderboardPage .component-container .asm-leaderboard-container .leaderboard form#countryLeaderboardForm {
  margin-left: 20px;
  margin-right: 20px;
}
@media screen and (min-width: 52.5em) {
  .leaderboardPage .component-container .asm-leaderboard-container .leaderboard form#countryLeaderboardForm {
    width: fit-content; /* avoid full page width (looks ugly in desktop) */
    margin: auto; /* center align */
    min-width: 700px;
  }
}
.leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-list-container {
  overflow-y: auto;
  height: 65vh;
  padding-left: 20px;
  padding-right: 20px;
  /* Not optimised for height below 600px */
}
@media screen and (max-height: 52.49em) {
  .leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-list-container {
    height: 60vh;
  }
}
@media screen and (max-height: 47.99em) {
  .leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-list-container {
    height: 55vh;
  }
}
@media screen and (max-height: 39.99em) {
  .leaderboardPage .component-container .asm-leaderboard-container .asm-leaderboard-list-container {
    height: 50vh;
  }
}
