/* ----------------------------------------------------
  CUSTOM FONTS
---------------------------------------------------- */
/* Ubuntu Font */
@font-face {
  font-family: "UbuntuLight";
  src: url("../../fonts/custom/Ubuntu-Light.ttf") format("truetype");
}
@font-face {
  font-family: "UbuntuMedium";
  src: url("../../fonts/custom/Ubuntu-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu";
  src: url("../../fonts/custom/Ubuntu-Regular.ttf") format("truetype");
}
/* Inter */
@font-face {
  font-family: "Inter";
  src: url("../../fonts/custom/Inter.ttf") format("truetype");
}
/* ----------------------------------------------------
  FONT OVERRIDES
---------------------------------------------------- */
body, .pure-g [class*=pure-u] {
  font-family: "Inter", Arial, sans-serif;
}

/*
  Login Page Titles,
  User Profile Section Titles,
  Other General Headers
*/
.login-title,
.asm-user-profile-section-header,
h2, h3, h4, h5, .asm-page-header {
  font-family: "UbuntuMedium", Helvetica, sans-serif;
}

/* Login Page Subtitle */
.login-sub-title {
  font-family: "UbuntuLight";
}

/*
  Topic Card / Challenge Card Titles
*/
.asm-topic-card-title,
.challengecard-description {
  font-family: "UbuntuMedium";
}

/* Topic Card / Challenge Card Description */
.card-desc,
.challengecard-footnote-wrap {
  font-family: "Ubuntu";
}
