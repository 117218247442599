/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/* ----------------------------------------------------
  BACKGROUND CUSTOMIZATIONS
---------------------------------------------------- */
/* Base Background */
body {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-attachment: fixed; /* This causes a white box bug at the bottom when scrolling down fast in mobile */
  /* Public-facing pages */
  /* Private facing pages */
}
body.page-not-loggedin {
  background-image: url("../../../images/custom/bg.png");
}
body:not(.page-not-loggedin) {
  background-image: url("../../../images/custom/bg.png");
}
body.frameLoginPage {
  background-color: white;
  background-image: none;
}

/* ----------------------------------------------------
  STRUCTURAL CUSTOMIZATIONS

  Note:
  1. Identifier classes such as "challengePage", "teamPage"... etc
     are now pegged to <body> tags.

  2. For ASM, a lot of structural customizations (e.g. white box) are
     pegged to "component-container".
---------------------------------------------------- */
/*
  Base component-container class
  (Applicable to all pages, page-specific overrides for pages with white box background
  or expanded sidebar are further defined below.)
*/
body {
  /* Public-facing pages */
  /* Private facing pages */
  /* Pages with EXPANDED sidebar */
  /* Pages with WHITE BOX background */
}
body.page-not-loggedin .component-container {
  margin: -50px 50px 30px 50px;
}
@media screen and (max-width: 63.99em) {
  body.page-not-loggedin .component-container {
    margin-top: 60px;
    /* Reset to no margins for mobile view */
    margin-left: 0;
    margin-right: 0;
  }
}
body:not(.page-not-loggedin) .component-container {
  margin: -50px 50px 30px 250px;
  padding-top: 20px !important;
}
@media screen and (max-width: 63.99em) {
  body:not(.page-not-loggedin) .component-container {
    margin-top: 60px;
    /* Reset to no margins for mobile view */
    margin-left: 0;
    margin-right: 0;
  }
}
body.projectPage .component-container {
  margin-left: 350px;
}
@media screen and (max-width: 63.99em) {
  body.projectPage .component-container {
    /* Reset to no left margin for mobile view */
    margin-left: 0;
  }
}
body.challengePage, body.leaderboardPage, body.notificationsPage, body.rewardsPage, body.activityPage, body.teamPage, body.teamsPage, body.achievementsPage, body.itemPage, body.bookmarksPage, body.usersPage, body.errorPage, body.error404Page {
  /* White box background with adjusted margins */
}
body.challengePage .component-container, body.leaderboardPage .component-container, body.notificationsPage .component-container, body.rewardsPage .component-container, body.activityPage .component-container, body.teamPage .component-container, body.teamsPage .component-container, body.achievementsPage .component-container, body.itemPage .component-container, body.bookmarksPage .component-container, body.usersPage .component-container, body.errorPage .component-container, body.error404Page .component-container {
  background-color: #fff;
  border-radius: 0px;
  display: block;
  height: fit-content;
  margin: -30px 50px 30px 250px;
}
@media screen and (max-width: 63.99em) {
  body.challengePage .component-container, body.leaderboardPage .component-container, body.notificationsPage .component-container, body.rewardsPage .component-container, body.activityPage .component-container, body.teamPage .component-container, body.teamsPage .component-container, body.achievementsPage .component-container, body.itemPage .component-container, body.bookmarksPage .component-container, body.usersPage .component-container, body.errorPage .component-container, body.error404Page .component-container {
    /* Adjusted margins for white box on mobile view */
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 63.99em) {
  body.bookmarksPage .component-container {
    background-color: transparent;
  }
}

/* ----------------------------------------------------
  RESETS
---------------------------------------------------- */
@media screen and (min-width: 64em) {
  .asm-grey-overlay.asm-overlay-desktop {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 25px;
  }
}

/* Remove border radiuses */
.button, a.button {
  border-radius: 0px;
}

.pure-form input, .pure-form button {
  border-radius: 0px !important;
}

select {
  border-radius: 0px !important;
}

.loading {
  margin-top: 100px;
}

/* ----------------------------------------------------
  OTHER CUSTOMIZATIONS
---------------------------------------------------- */
/*
  Remove scrollbar which renders for some pages that don't require scrolling
  #OffsettingSomeOffsetsWhichWereOffsettingOtherOffsets
*/
@media screen and (min-width: 64em) {
  body:not(.page-not-loggedin) {
    height: calc(100% - 70px); /* Prevent awkward spacing at the bottom */
  }
}

/* Positioning tweaks to cater for topbar/sidebar customisations */
body {
  /* For sidebar to be fixed on the left */
  /* Disable left margin in public pages (sidebar only available in logged in views) */
  /* Adjust container to cater for back button */
}
@media screen and (min-width: 64em) {
  body .container .innerblock {
    max-width: none;
  }
}
body .page-not-loggedin .component-container {
  margin-left: 0 !important;
  padding-top: 60px !important;
}
@media screen and (max-width: 63.99em) {
  body.errorPage .container, body.error404Page .container {
    padding-top: 0;
  }
}
@media screen and (min-width: 64em) {
  body.resetPasswordPage .fullscreen-frame-wrap, body.registerPage .fullscreen-frame-wrap, body.postLoginPage .fullscreen-frame-wrap {
    border-radius: 0px;
    padding-top: 45px; /* Add 10px more than normal */
  }
}
body.teamPage .team .container.darkbg {
  /* Reverse colours to support back button */
  background-color: white;
  color: black !important;
}
body .team .container.darkbg, body .profile .container.darkbg {
  border-radius: 25px 25px 0 0;
  border-color: black;
}
@media screen and (max-width: 63.99em) {
  body {
    /* Adjustments for player list page - reduce paddings (real estate is precious in mobile) */
  }
  body.usersPage .teams.container { /* don't ask me why teams class is in player list page (weird code came from web-blank) */
    padding-top: 0;
  }
  body.usersPage .teams.container .innerblock .verticalpadding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
body .comments-container {
  background-color: #fff;
  border-radius: 0px;
  display: block;
  height: fit-content;
  margin-left: 50px;
  margin-top: 70px;
  margin-right: 50px;
  padding-top: 50px;
}
@media screen and (max-width: 63.99em) {
  body .comments-container {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* Page title is added for some pages */
.asm-page-header {
  text-align: center;
  font-size: 1.5em;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Remove transparency for awardable actions due to poor contrast against dark background */
.awardable-actions-wrapper.visible {
  opacity: 1 !important;
}

/* Adjustments for specific pages in mobile*/
@media screen and (max-width: 63.99em) {
  .alert-wrapper .alert {
    top: 60px !important;
  }
  .page-not-loggedin .alert-wrapper .alert {
    top: 0 !important;
  }
}
.highlightbox {
  background-color: #8E7A96;
}

textarea {
  border-radius: 0px !important;
}

/* ----------------------------------------------------
  HIDE UNUSED ELEMENTS
---------------------------------------------------- */
/* Hide user widget at the bottom */
.profile-drawer {
  display: none;
}

/* Hide topic progress bar */
.progressbar {
  display: none;
}
