/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
.topic-container .slick-list {
  padding-top: 90px;
  margin-top: -30px;
}
.topic-container .challenge-card {
  border-radius: 20px;
  min-height: 400px;
  background-image: none;
  background-color: #F5F0EB;
  margin-top: 25px; /* To account for extra height taken by hexagons */
  /* Challenge title (strange naming is from web-blank) */
  /* Challenge description (not present in web-blank) */
}
@media screen and (min-width: 64em) {
  .topic-container .challenge-card {
    height: 65vh;
  }
}
.topic-container .challenge-card .challengecard-description {
  background-color: #F5F0EB;
  color: black;
  margin-top: 90px;
  text-align: center;
  padding: 10px 20px;
}
.topic-container .challenge-card .challengecard-footnote-wrap {
  color: black;
  padding-top: 15px;
  font-weight: normal;
  position: absolute;
  top: 160px;
  overflow: auto;
  height: calc(100% - 250px);
  width: 100%;
}
.topic-container .challenge-card .challengecard-footnote-wrap .challengecard-footnote {
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}
.topic-container .challenge-card .challengecard-footnote-wrap .challengecard-footnote img {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 47.99em) {
  .topic-container .challenge-card .challengecard-footnote-wrap .challengecard-footnote {
    font-size: 0.8em;
  }
}
.topic-container .challenge-card .icons-wrap {
  top: -100px;
}
.topic-container .challenge-card .icons-wrap .asm-challenge-img {
  display: inline-block;
  clip-path: polygon(50% 0, 100% 23%, 100% 77%, 50% 100%, 0 77%, 0 23%);
  background-position: center;
  background-size: cover;
  height: 180px;
  width: 160px;
}
.topic-container .challenge-card .challengeicon.points-top {
  position: relative;
  top: -65px;
  right: -21px;
}
.topic-container .challenge-card .challengeicon.points-top-none {
  background: transparent;
}
.topic-container .challenge-card .challengeicon.icon:before {
  top: -65px;
  left: -21px;
}
.topic-container .challenge-card .challengeicon.icon:after {
  top: -65px;
  left: -20px;
}
.topic-container .challenge-card .challengecard-bottom .button-wrap {
  width: 100%;
  padding-left: 20px;
}
.topic-container .challenge-card .challengecard-bottom .asm-challenge-buttons {
  text-align: center;
}
.topic-container .challenge-card .challengecard-bottom .asm-challenge-buttons .button-challenge-card {
  max-width: 200px;
}
.topic-container .challenge-card .challengecard-bottom .asm-challenge-buttons .button-challenge-card.inactive {
  background-color: #bbb;
}
@media screen and (max-width: 63.99em) {
  .topic-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
