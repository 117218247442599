/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
@media screen and (min-width: 64em) {
  .asmUserProfilePage .back-button-wrap {
    display: none;
  }
}

.asm-user-profile {
  width: 100%;
  /* Common Structure for Desktop & Mobile */
  /* Profile Photo */
  /* Reward Store Button Link */
  /* Left Section for Desktop View */
  /* Right Section for Desktop View */
  /* Mobile View */
}
.asm-user-profile .asm-user-profile-section {
  background-color: white;
}
.asm-user-profile .asm-user-profile-section .asm-user-profile-section-header {
  background-color: #8E7A96;
  border-top: 1px #8E7A96;
  padding: 10px 20px;
  color: white;
  font-size: 1.1em;
}
.asm-user-profile .asm-user-profile-section .asm-user-profile-section-content {
  padding: 30px;
}
.asm-user-profile .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset {
  margin-bottom: 30px;
  font-size: 1.05em;
}
.asm-user-profile .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset .asm-user-profile-field {
  font-weight: bold;
  text-transform: capitalize;
}
.asm-user-profile .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset .asm-user-profile-data {
  margin-left: 5px;
}
@media screen and (min-width: 64em) {
  .asm-user-profile .asm-user-profile-photo {
    width: 250px;
    display: inline-block;
  }
}
.asm-user-profile .asm-user-profile-photo .card-img {
  width: 100%;
  padding-bottom: 100%; /* square box */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}
.asm-user-profile .asm-user-profile-rewards .asm-user-profile-reward-icon {
  text-align: center;
}
.asm-user-profile .asm-user-profile-rewards .asm-user-profile-reward-icon .fas {
  /*color: $asm-grey;*/
  font-size: 5em;
}
.asm-user-profile .asm-user-profile-rewards .asm-user-profile-reward-link {
  float: right;
  margin-top: 10px;
}
@media screen and (min-width: 64em) {
  .asm-user-profile .asm-user-profile-left .asm-user-profile-info .asm-user-profile-stats {
    width: calc(100% - 250px - 20px);
    height: 250px;
    display: inline-block;
    position: relative;
    left: 20px;
  }
}
.asm-user-profile .asm-user-profile-left .asm-user-profile-activity {
  margin-top: 20px;
  width: 100%;
}
.asm-user-profile .asm-user-profile-left .asm-user-profile-activity .asm-user-profile-section .asm-user-profile-section-content {
  padding-right: 0px;
  padding-top: 10px;
}
.asm-user-profile .asm-user-profile-left .asm-user-profile-activity .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-activity-list {
  height: calc(80vh - 250px);
  overflow-y: auto;
}
.asm-user-profile .asm-user-profile-left .asm-user-profile-activity .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-activity-list .claimcard-line {
  padding-right: 20px;
}
.asm-user-profile .asm-user-profile-right {
  vertical-align: top !important;
  padding-left: 20px;
}
.asm-user-profile .asm-user-profile-right .asm-user-profile-achievements .asm-user-profile-section .asm-user-profile-section-content {
  padding-right: 0px;
  padding-top: 10px;
}
.asm-user-profile .asm-user-profile-right .asm-user-profile-achievements .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-achievement-list {
  height: 55vh;
  padding-right: 20px;
  overflow-y: auto;
}
.asm-user-profile .asm-user-profile-right .asm-user-profile-rewards {
  margin-top: 20px;
}
.asm-user-profile .asm-user-profile-right .asm-user-profile-rewards .asm-user-profile-section .asm-user-profile-section-content {
  padding: 10px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-section {
  margin-bottom: 20px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset .asm-user-profile-field {
  margin-left: 5px;
  font-weight: normal;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset .asm-user-profile-data {
  margin-left: 0;
  font-weight: bold;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content {
  padding: 10px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-photo {
  width: 150px;
  height: 150px;
  display: inline-block;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset-mobile {
  display: inline-block;
  margin-left: 20px;
  margin-top: 40px;
  vertical-align: top;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset-mobile .asm-user-profile-dataset {
  margin-bottom: 5px;
  font-size: 1em;
}
@media screen and (max-width: 24.99em) {
  .asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-photo {
    width: 100px;
    height: 100px;
  }
  .asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset-mobile {
    margin-top: 15px;
  }
  .asm-user-profile .asm-user-profile-mobile .asm-user-profile-info .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-dataset-mobile .asm-user-profile-dataset {
    font-size: 0.9em;
  }
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-activity .asm-user-profile-section .asm-user-profile-section-content {
  padding-right: 0px;
  padding-top: 10px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-activity .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-activity-list {
  max-height: 40vh;
  overflow-y: auto;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-activity .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-activity-list .claimcard-line {
  padding-right: 20px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-achievements .asm-user-profile-section .asm-user-profile-section-content {
  padding-right: 0px;
  padding-top: 10px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-achievements .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-achievement-list {
  height: 160px;
  padding-right: 20px;
  overflow-y: auto;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-achievements .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-achievement-list .asm-user-profile-achievement {
  width: 25%;
  display: inline-block;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-rewards .asm-user-profile-section .asm-user-profile-section-content {
  padding: 10px;
}
.asm-user-profile .asm-user-profile-mobile .asm-user-profile-rewards .asm-user-profile-section .asm-user-profile-section-content .asm-user-profile-reward-link .button {
  margin-bottom: 0;
}
