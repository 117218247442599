/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
@media screen and (min-width: 64em) {
  .alert-wrapper .alert {
    top: 0 !important;
  }
}

/* ----------------------------------------------------
  CUSTOMIZATION FOR BACK BUTTON
---------------------------------------------------- */
.topbar-wrap {
  /* Logged-in Views */
}
.topbar-wrap .topbar {
  /* Resets for Back Button */
}
.topbar-wrap .topbar .back-button-wrap .back-button-text i.fa-caret-left {
  margin-right: 10px;
}
@media screen and (min-width: 64em) {
  .topbar-wrap .topbar .back-button-wrap .back-button-text {
    color: black;
  }
  .topbar-wrap .topbar .back-button-wrap .back-button-text i.fa-caret-left {
    color: white;
    background-color: #8E7A96;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .topbar-wrap .topbar .back-button-wrap .back-button-text i.fa-caret-left:before {
    font-size: 24px;
    margin-left: 6px;
    vertical-align: top; /* looks more like middle */
  }
  .topbar-wrap .topbar .back-button-wrap .back-button-text .asm-back-text-wrap {
    position: relative;
    top: -4px;
  }
}
@media screen and (max-width: 63.99em) {
  .topbar-wrap .topbar .back-button-wrap {
    margin-top: 10px;
  }
}
.topbar-wrap.logged-in .topbar {
  /* Back Button */
}
@media screen and (min-width: 64em) {
  .topbar-wrap.logged-in .topbar {
    position: relative;
    float: left;
  }
}
.topbar-wrap.logged-in .topbar .back-button-wrap {
  /* Desktop: Move back button such that it floats within the white container although it is not a child */
}
@media screen and (min-width: 64em) {
  .topbar-wrap.logged-in .topbar .back-button-wrap {
    top: 35px;
    left: 260px;
  }
}

/* Logged out views (e.g. reset password page) */
.page-not-loggedin .topbar {
  background-color: transparent;
  /* Back Button */
}
@media screen and (min-width: 64em) {
  .page-not-loggedin .topbar {
    display: initial;
    color: black;
    margin-left: 50px;
  }
}
.page-not-loggedin .topbar .back-button-wrap {
  /* Desktop: Move back button such that it floats within the container although it is not a child */
}
@media screen and (min-width: 64em) {
  .page-not-loggedin .topbar .back-button-wrap {
    top: 25px;
    left: 10px;
  }
}

/* ----------------------------------------------------
  CUSTOMIZATION FOR NAVIGATION MENU TOGGLE (MOBILE VIEWS)
---------------------------------------------------- */
.topbar {
  /* Reduce height of topbar as it has too much padding at the bottom */
  height: 60px;
  /* Menu Icon */
  /* Right Side Menu (Only togglable on smaller screens) */
  /* ----------------------------------------------------
    HIDE UNUSED ELEMENTS
  ---------------------------------------------------- */
}
.topbar.topbarbg {
  /* Provide opacity so that the back button is visible */
  background-color: rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 64em) {
  .topbar.topbarbg {
    background: transparent;
  }
}
.topbar .icons-wrap .menu-icon {
  background-color: #8E7A96;
  background-position: center center !important;
  background-size: 80%;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  /* Hide Top Menu Toggle in Desktop view */
}
@media screen and (min-width: 64em) {
  .topbar .icons-wrap .menu-icon {
    display: none;
  }
}
@media screen and (min-width: 64em) {
  .topbar .bm-menu-wrap {
    display: none;
  }
}
.topbar .bm-menu {
  background-color: #8E7A96;
  padding-top: 4em;
  /* Navigation Icon Wrapper */
}
.topbar .bm-menu .menu-item {
  background-color: #8E7A96;
  margin-bottom: 8px;
  padding: 0.6em 2.3em;
}
.topbar .bm-menu .asm-icon-container {
  position: relative;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  /* Hexagon Outline */
  /* Navigation Icon */
}
.topbar .bm-menu .asm-icon-container .asm-icon-hexagon {
  background: transparent url(../../images/custom/hexagon_white.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
}
.topbar .bm-menu .asm-icon-container .asm-icon {
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 16px;
}
.topbar .title-wrap {
  display: none;
}
