/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
.challenge .container .innerblock {
  max-width: none;
}
@media screen and (min-width: 64em) {
  .challenge .container .innerblock {
    margin-top: 10px;
  }
}
.challenge .slick-prev:before,
.challenge .slick-next:before {
  color: #8E7A96;
}
.challenge .challenge-topic-nav {
  border-radius: 0px;
}
.challenge #claimform {
  padding-top: 0;
}
.challenge .challenge-page-divider {
  background-color: white;
}
.challenge .challenge-page-divider hr {
  margin-top: 20px;
  border: 1px solid #ddd; /* match with quiz option buttons */
}
.challenge a.link .container {
  background-color: #eee;
}
.challenge a.link .container .right-caret {
  display: none;
}
.challenge .challenge-nav.challenge-nav-prev {
  left: 10px !important;
}
.challenge .challenge-nav.challenge-nav-next {
  right: 10px !important;
}
@media screen and (max-width: 400px) {
  .challenge .challenge-nav.challenge-nav-next {
    right: 13px !important;
  }
}
@media screen and (max-width: 22.5em) {
  .challenge .challenge-nav.challenge-nav-next {
    right: 17px !important;
  }
}
@media screen and (max-width: 20em) {
  .challenge .challenge-nav.challenge-nav-next {
    right: 20px !important;
  }
}
.challenge .asm-challenge-nav-btn .challenge-nav {
  color: #8E7A96;
  /*&:hover {
    color: $asm-red;
  }*/
}
.challenge .asm-challenge-nav-btn .challenge-nav i.fas {
  color: white;
  background-color: #8E7A96;
  width: 44px;
  height: 44px;
  border-radius: 50%; /* half of width to simulate circle */
  font-size: 2.8em;
  /* Use same size as mobile */
}
.challenge .asm-challenge-nav-btn .challenge-nav i.fas.fa-caret-left:before {
  margin-left: 11px;
}
.challenge .asm-challenge-nav-btn .challenge-nav i.fas.fa-caret-right:before {
  margin-left: 18px;
}
@media screen and (max-width: 63.99em) {
  .challenge .asm-challenge-nav-btn .challenge-nav i.fas {
    font-size: 2em;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .challenge .asm-challenge-nav-btn .challenge-nav i.fas.fa-caret-left:before {
    margin-left: -5px;
  }
  .challenge .asm-challenge-nav-btn .challenge-nav i.fas.fa-caret-right:before {
    margin-left: 5px;
  }
}
@media screen and (max-width: 63.99em) {
  .challenge .asm-challenge-nav-btn .challenge-nav {
    bottom: auto;
    margin-top: -40px;
    top: 0px;
  }
}
@media screen and (min-width: 64em) {
  .challenge .asm-challenge-nav-btn .challenge-nav {
    top: 0px;
    margin-top: 40px;
  }
}
.challenge .asm-challenge-image {
  display: flex;
  justify-content: center;
}
.challenge .asm-challenge-image .card-img {
  border-radius: 0px;
  height: 400px;
  width: 95%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 89.99em) {
  .challenge .asm-challenge-image .card-img { /* below 1440px */
    height: 300px;
  }
}
@media screen and (max-width: 69.99em) {
  .challenge .asm-challenge-image .card-img { /* below 1120px */
    height: 200px;
  }
}
@media screen and (max-width: 47.99em) {
  .challenge .asm-media-fit-width {
    width: 100%;
  }
}
.challenge .challenge-icons-position {
  position: relative;
}
.challenge .asm-challenge-image-wrapper {
  position: relative;
}
.challenge .asm-challenge-image-wrapper .button.icon {
  position: absolute;
  z-index: 2;
}
.challenge .asm-challenge-description img, .challenge #completed img {
  max-width: 100%; /* constraint images in challenge description so that it will not extend beyond screen width */
  height: auto;
}
.challenge .iframe-container-challenge-profile-pdf {
  border-radius: 0px;
  height: 400px;
  background-color: black;
}
@media screen and (max-width: 63.99em) {
  .challenge .iframe-container-challenge-profile-pdf {
    height: 200px;
  }
}
.challenge .iframe-container-challenge-profile-pdf iframe {
  width: calc(100% - 100px);
  margin-left: 50px;
}
.challenge .iframe-container-challenge-profile .button.icon,
.challenge .asm-challenge-image-wrapper .button.icon {
  right: 15px;
  top: 15px;
}
.challenge .iframe-container-challenge-profile {
  border-radius: 0px;
  height: 400px;
  padding-bottom: 0;
  background-color: black;
}
@media screen and (max-width: 89.99em) {
  .challenge .iframe-container-challenge-profile { /* below 1440px */
    height: 300px;
  }
}
@media screen and (max-width: 69.99em) {
  .challenge .iframe-container-challenge-profile { /* below 1120px */
    height: 200px;
  }
}
.challenge .asm-embed-offset-top .iframe-container-challenge-profile iframe {
  margin-top: 25px;
}
@media screen and (max-width: 63.99em) {
  .challenge .asm-embed-offset-top .iframe-container-challenge-profile iframe {
    margin-top: 15px;
  }
}
.challenge .asm-challenge-action-buttons {
  display: block;
}
.challenge .asm-challenge-action-buttons .button.inline-block, .challenge .asm-challenge-action-buttons a.button.inline-block {
  vertical-align: top;
}
.challenge .button.quizinput, .challenge a.button.quizinput {
  border-width: 2px;
  border-radius: 0px;
  /* Fit quiz option buttons to content in desktop mode */
}
@media screen and (min-width: 64em) {
  .challenge .button.quizinput, .challenge a.button.quizinput {
    width: 100%;
    width: fit-content;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.challenge .button.quizinput.selected, .challenge a.button.quizinput.selected {
  background-color: transparent;
  border-color: #8E7A96;
}
.challenge .challenge-topic-nav {
  margin-left: 10px !important;
}
.challenge #claimform .button.fullwidth.quizinput {
  width: 100% !important;
}

.challenge-completed-modal-content .modal-body {
  border-radius: 0;
}
.challenge-completed-modal-content .modal-close-container {
  border-radius: 0;
  border-top: 0;
}
.challenge-completed-modal-content .modal-close-container .button {
  background-color: white;
  color: black;
  border: solid 2px #807D7D;
}
.challenge-completed-modal-content .modal-close-container #goToNextChallengeButton {
  background-color: #8E7A96;
}
.challenge-completed-modal-content .modal-close-container #goToNextChallengeButton:hover {
  background-color: #74627c;
}
.challenge-completed-modal-content .challenge-completion-container img {
  max-width: 100%; /* constraint images in challenge description so that it will not extend beyond screen width */
  height: auto;
}

@media screen and (max-width: 768px) {
  .asm-challenge-text {
    text-align: center;
  }
}

/* custom challenge icons */
@media screen and (max-width: 768px) {
  .asm-challengeicon {
    width: 25px;
    height: 25px;
  }
  .asm-challengeicon .points {
    margin-top: 6px;
    font-size: 10px;
  }
  .asm-challengeicon.icon {
    font-size: 25px;
  }
  .asm-challengeicon.icon:after {
    font-size: 23px;
  }
}
