/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/* ----------------------------------------------------
  PROJECT SIDEBAR
---------------------------------------------------- */
.page-container .sidebar-wrapper {
  flex-grow: 0;
}

.sidebar {
  background-color: transparent;
  top: 0 !important;
  bottom: auto !important;
  /* Main menu links container - will have .sidebar-expanded or .sidebar-collapsed appended */
}
.sidebar .asm-menu {
  margin-top: 20px;
  margin-left: 20px;
}
.sidebar:not(.asm-menu-fixed) {
  position: relative !important; /* so that whole page scrolls together */
}
.sidebar .asm-menu-links-container {
  float: right;
  position: relative;
  background-color: #8E7A96;
  color: #fff;
  block-size: fit-content;
  padding-top: 25px;
  padding-bottom: 15px;
  /* Logo */
  /* Individual Link */
  /* Expanded Sidebar */
  /* Collapsed Sidebar */
}
.sidebar .asm-menu-links-container .asm-menu-logo {
  text-align: center;
}
.sidebar .asm-menu-links-container .asm-menu-links {
  padding-top: 10px;
}
.sidebar .asm-menu-links-container .asm-menu-links .asm-menu-link {
  margin-bottom: 8px;
  background-color: #8E7A96;
  display: block;
  padding-left: 20px;
  padding: 8px 30px 8px 20px;
  /* Navigation Icon Wrapper */
}
.sidebar .asm-menu-links-container .asm-menu-links .asm-menu-link .asm-icon-container {
  display: inline-block;
  width: 34px;
  height: 34px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  /* Hexagon Outline */
  /* TODO: Change to square*/
  /* Navigation Icon */
}
.sidebar .asm-menu-links-container .asm-menu-links .asm-menu-link .asm-icon-container .asm-icon-hexagon {
  background: transparent url(../../images/custom/hexagon_white.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
}
.sidebar .asm-menu-links-container .asm-menu-links .asm-menu-link .asm-icon-container .asm-icon {
  font-size: 16px;
  margin-top: 8px;
}
.sidebar .asm-menu-links-container .asm-menu-links .asm-menu-label {
  padding-left: 10px;
}
.sidebar .asm-menu-links-container.sidebar-expanded {
  margin-top: 90px;
  min-width: 200px;
}
.sidebar .asm-menu-links-container.sidebar-expanded .asm-menu-logo-collapsed {
  display: none;
}
.sidebar .asm-menu-links-container.sidebar-expanded .asm-menu-logo-expanded {
  display: block;
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
}
.sidebar .asm-menu-links-container.sidebar-expanded .asm-menu-logo-expanded img {
  width: 120px;
}
.sidebar .asm-menu-links-container.sidebar-collapsed {
  width: 80px;
  margin-top: 70px;
  margin-left: 100px;
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-logo-expanded {
  display: none;
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-logo-collapsed {
  display: block;
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-logo-collapsed img {
  width: 100%;
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-label {
  display: none;
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-links .asm-menu-link {
  padding: 5px 20px;
  text-align: center;
  /* Navigation Icon Wrapper */
  /* Navigation Icon */
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-links .asm-menu-link .asm-icon-container {
  width: 40px;
  height: 40px;
}
.sidebar .asm-menu-links-container.sidebar-collapsed .asm-menu-links .asm-menu-link .asm-icon {
  margin-top: 10px;
  font-size: 20px;
}
