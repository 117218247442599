/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
#completed .correct {
  color: #85d02f;
}
#completed .wrong {
  color: #B71C1C;
}
#completed i {
  color: #797979;
}
