.teams .team-container {
  display: table;
  width: 100%;
}
.teams .team-container > div {
  display: table-cell;
  vertical-align: middle;
}

.team-card-container {
  position: relative;
  width: 100%;
}

.team-card {
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px 0;
  padding: 20px;
}
.team-card .square-image-wrapper {
  flex-basis: 80px;
}
.team-card p {
  margin-top: 0.75em;
  margin-bottom: 0;
}
.team-card .team-current-user {
  color: #fff;
  font-style: italic;
}
.team-card .team-current-user-image {
  margin-right: 20px;
  width: 36px;
  flex-basis: 36px;
}
