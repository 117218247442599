/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/* Position IFRAME container to support sidebar */
.fullscreen-frame-wrap {
  background-color: #fff;
}
@media screen and (min-width: 64em) {
  .fullscreen-frame-wrap {
    position: relative;
    border-radius: 0px;
    top: 0;
    padding-top: 50px;
    padding-bottom: 20px;
    height: 80vh;
  }
}
@media screen and (min-width: 64em) and (min-height: 52.5em) {
  .fullscreen-frame-wrap {
    height: 85vh;
  }
}
@media screen and (max-width: 63.99em) {
  .fullscreen-frame-wrap {
    top: 60px !important;
  }
}

@media screen and (min-width: 64em) {
  .page-not-loggedin .fullscreen-frame-wrap {
    height: 85vh;
  }
}
@media screen and (min-width: 64em) and (min-height: 64em) {
  .page-not-loggedin .fullscreen-frame-wrap {
    height: 90vh;
  }
}
