/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
.asm-menu-links-container.sidebar-expanded {
  margin-left: 80px;
}

body .project {
  background-color: transparent;
}
body .project .desktop-project .asm-topic-card-container .asm-topic-card {
  width: 100%;
  max-width: none;
}
body .project .mobile-project {
  overflow-y: scroll;
  background-color: transparent;
  position: initial;
}
body .project .asm-topic-card {
  background-color: #F5F0EB;
  color: black;
  height: 280px;
  box-shadow: none;
  margin: 0 0 30px;
  border-radius: 0px;
}
@media screen and (max-width: 63.99em) {
  body .project .asm-topic-card {
    height: 320px;
  }
}
body .project .asm-topic-card .asm-topic-card-img .card-img {
  height: 280px;
  padding-top: 0;
}
@media screen and (max-width: 63.99em) {
  body .project .asm-topic-card .asm-topic-card-img {
    visibility: hidden;
  }
}
body .project .asm-topic-card .asm-topic-card-info {
  padding-top: 5px;
  position: relative;
}
@media screen and (max-width: 63.99em) {
  body .project .asm-topic-card .asm-topic-card-info {
    width: 100%;
    padding-top: 0;
  }
  body .project .asm-topic-card .asm-topic-card-info .asm-topic-card-img-mobile .card-img {
    height: 100px;
    padding-top: 0;
  }
}
@media screen and (min-width: 64em) {
  body .project .asm-topic-card .asm-topic-card-info .asm-topic-card-img-mobile {
    display: none;
  }
}
body .project .asm-topic-card .asm-topic-card-info .asm-topic-card-title {
  margin: 5px 20px; /* Left align with topic description */
  font-size: 1.5em;
  /* Truncate long title */
  /*white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}
@media screen and (max-width: 63.99em) {
  body .project .asm-topic-card .asm-topic-card-info .asm-topic-card-title {
    font-size: 1em;
  }
}
body .project .asm-topic-card .asm-topic-card-info .card-scroll { /* Topic description */
  background-color: #F5F0EB;
  padding-top: 160px; /* seems to behave like height */
  overflow-y: auto;
}
@media screen and (max-width: 63.99em) {
  body .project .asm-topic-card .asm-topic-card-info .card-scroll {
    padding-top: 120px;
    font-size: 0.9em;
  }
}
body .project .asm-topic-card .asm-topic-card-info .card-action, body .project .asm-topic-card .asm-topic-card-info .card-locked {
  width: 250px;
  padding-top: 0;
  height: 40px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
@media screen and (max-width: 63.99em) {
  body .project .asm-topic-card .asm-topic-card-info .card-action, body .project .asm-topic-card .asm-topic-card-info .card-locked {
    width: auto;
    bottom: -30px;
    left: 20px;
    right: 20px;
  }
}
