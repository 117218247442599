$asm-red: #c63b36;
$asm-dark-red: #b82e29;
$asm-grey: #807D7D;
$asm-lightgrey: #cbcbcb;
$asm-lightpurple: #8E7A96;
$asm-sand: #F5F0EB;

$cta: $asm-lightpurple;
$cta-hover: darken($cta, 10%);
$cta-disabled: #bbb;
$cta-disabled-lighter: #ddd;
