@import 'src/library/css/core/variables.scss';

.loading{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__dots {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: 30px 0 30px 0;

    // with animation
    &__dot {
      background-color: #000;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0 5px;
      color: #587B7F;

      &:nth-child(1) {
        animation: bounce 0.75s 0s infinite;
      }

      &:nth-child(2) {
        animation: bounce 0.75s 0.2s infinite;
      }

      &:nth-child(3) {
        animation: bounce 0.75s 0.4s infinite;
      }
    }

    // without animation (for leaderboard page)
    &__stagnent-dot {
      height: 15px;
      width: 15px;
      margin: 0px 5px;
      border-radius: 50%;
      background-color: $cta;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
