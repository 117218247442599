/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/* Colour overrides for challenge type icons */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Define height of top bar
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # MARGIN & PADDING
\*------------------------------------*/
/*------------------------------------*\
    # BORDERS
\*------------------------------------*/
/*------------------------------------*\
    # TEXT
\*------------------------------------*/
.mobile-project-profile-link {
  /* Ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*------------------------------------*\
    # CURSOR TYPES
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
/*------------------------------------*\
    # IFRAMES
\*------------------------------------*/
/*
  MobileProject.scss sets styling for
  components only in MobileProjectPage
*/
.project .mobile-project {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #F0F0F0;
  /*
    set height here to be passed to child components:
    remember to deduct from 'top' value
  */
  height: calc(100% - 70px);
  box-sizing: border-box;
}
.project .mobile-project .project-home-button {
  position: fixed;
  top: calc((100% - 70px) / 2);
  left: 50px;
  z-index: 10;
}
@media screen and (max-width: 63.99em) {
  .project .innerblock > .automargin {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.mobile-project-main {
  position: relative;
}

.mobile-project-sidebar, .mobile-project-list-icon-wrapper {
  width: 60vw;
  max-width: 350px;
}

.mobile-project-sidebar {
  position: absolute;
  bottom: 15px;
  left: -40px;
}

.mobile-project-list-icon-wrapper {
  position: relative;
  top: 15px;
  padding-left: 5px;
  left: -30px;
}

.mobile-project-profile-link {
  display: block;
}
.mobile-project-profile-link a, .mobile-project-profile-link span {
  white-space: inherit;
}

.mobile-project-points-text {
  font-size: 0.75em;
}

.mobile-project-profile-points-text {
  font-size: 1.67em;
}

.mobile-project-profile-button-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.mobile-project-profile-button-container > div {
  margin-right: 10px;
}

.mobile-project-profile-button {
  display: block;
  position: relative;
}
.mobile-project-profile-button .fas {
  position: absolute;
  font-size: 0.875em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 22.5em) {
  .mobile-project-profile-button .fas {
    font-size: 1em;
  }
}
@media screen and (min-width: 30em) {
  .mobile-project-profile-button .fas {
    font-size: 1.5em;
  }
}
.mobile-project-profile-button .notification-dot {
  height: 22.5%;
  width: 22.5%;
  position: absolute;
  top: 22.5%;
  right: 22.5%;
}

.mobile-project-profile-button-border {
  border-radius: 50%;
}

.mobile-project-main-image {
  background: url("../../images/core/project/gamelist_glogo.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 45%;
  right: 10px;
  width: 75px;
  height: 56px;
  z-index: 1;
  opacity: 0.5;
}
